import React, { useCallback, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { DeliveryDateTime } from '../DeliveryDateTime/DeliveryDateTime';
import { DeliveryAddress } from '../DeliveryAddress/DeliveryAddress';
import { GeneralInfo } from '../GeneralInfo/GeneralInfo';
import { ClientInfo } from '../ClientInfo/ClientInfo';
import { ClientComment } from '../ClientComment/ClientComment';

import styles from './Booking.module.css';
import { Button } from '../../shared/Button/Button';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectChosenDate, selectChosenTime } from '../DeliveryDateTime/deliveryDateTimeSlice';
import { selectDeliveryOptions, selectSelfDeliveryOfficeId } from '../DeliveryAddress/deliveryAddressSlice';
import { selectClientFullName, selectClientPhone, selectClientId, setClientId } from '../ClientInfo/clientInfoSlice';
import { createUserThunk, bookItemThunk } from './bookingSlice';
import { selectClientComment } from '../ClientComment/clientCommentSlice';
import { selectItemId } from '../GeneralInfo/generalInfoSlice';
import { SuccessScreen } from '../SuccessScreen/SuccessScreen';

export function Booking() {
  const [success, setSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const chosenDate = useAppSelector(selectChosenDate);
  const chosenTime = useAppSelector(selectChosenTime);
  const deliveryOptions = useAppSelector(selectDeliveryOptions);
  const fullName = useAppSelector(selectClientFullName);
  const phone = useAppSelector(selectClientPhone);
  const userId = useAppSelector(selectClientId);
  const stockID = useAppSelector(selectSelfDeliveryOfficeId);
  const comment = useAppSelector(selectClientComment);
  const chosenItem = useAppSelector(selectItemId);

  const handleClick = useCallback(async () => {
    try {
      if (!phone || !fullName || !chosenItem || (!stockID && !deliveryOptions?.address)) {
        return;
      }

      let newId: null | string = null;
      let endDateTime: null | number = null;

      if (!userId) {
        const response = await dispatch(
          createUserThunk({
            FIO: fullName,
            PhMain: phone,
          }),
        );

        newId = (response as any)?.payload?.data?.id;

        if (newId) {
          dispatch(setClientId({ id: newId }));
        }
      }

      if (chosenDate !== null && chosenTime?.to !== undefined) {
        endDateTime = new Date(chosenDate).setHours(chosenTime.to, chosenTime.minutes, 0, 0);
        const stockId = stockID ? stockID : 'a1e18174-add8-41f8-8fab-0e96ca081adf';
        const clientComment = `Коментар клієнта: ${comment}.`;
        const clientAddress = `Адрес доставки: ${deliveryOptions?.address}.`;
        let resultComment = ' -10% Сайт.';

        if (comment) {
          resultComment += clientComment;
        }
        if (deliveryOptions?.address) {
          resultComment += clientAddress;
        }

        const response = await dispatch(
          bookItemThunk({
            clientID: userId || newId,
            stockID: stockId,
            chosenItem,
            comment: resultComment,
            endDateTime,
            isCreatedByClient: true,
          }),
        );

        setSuccess(!!response);
      }
    } catch (err) {
      console.error(err);
    }
  }, [
    chosenDate,
    chosenItem,
    chosenTime,
    comment,
    deliveryOptions?.address,
    dispatch,
    fullName,
    phone,
    stockID,
    userId,
  ]);

  const isButtonDisabled = !chosenDate || !chosenTime || !phone || !fullName || (!stockID && !deliveryOptions?.address);

  const ToolTipText = () => {
    return (
      <div style={{ maxWidth: 200, lineHeight: 1.5 }}>
        {!chosenDate && !chosenTime && <p>Оберіть дату і час резерву</p>}
        {!deliveryOptions?.address && !stockID && <p>Вкажіть спосіб доставки</p>}
        {!phone && <p>Підтвердіть номер телефону</p>}
        {!fullName && <p>Введіть прізвище та ім'я</p>}
      </div>
    );
  };

  return (
    <div className={styles.root}>
      {!success && (
        <>
          {isButtonDisabled && (
            <ReactTooltip
              id="bookingBtn-tip"
              place="top"
              type="dark"
              effect="solid"
              getContent={() => <ToolTipText />}
            />
          )}
          <GeneralInfo />
          <DeliveryDateTime />
          <DeliveryAddress />
          <ClientComment />
          <ClientInfo />

          <Button
            className={styles.submitButton}
            data-tip
            data-for="bookingBtn-tip"
            disabled={isButtonDisabled}
            onClick={handleClick}
          >
            Зарезервувати
          </Button>
        </>
      )}

      {success && <SuccessScreen />}
    </div>
  );
}

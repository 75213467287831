import React, { ChangeEvent, useCallback, useState } from 'react';
import Typography from '../../shared/Typography/Typography';
import { Input } from '../../shared/Input/Input';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import styles from './ClientComment.module.css';
import { choseComment } from './clientCommentSlice';

export const ClientComment = () => {
  const dispatch = useAppDispatch();
  const [comment, setComment] = useState<string>('');

  const handleCommentChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setComment(value);
  }, []);

  const handleCommentOnBlur = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      dispatch(choseComment({ comment: value }));
    },
    [dispatch],
  );

  return (
    <div className={styles.root}>
      <Typography variant="h2" component="h2" align="center" marginBottom={15}>
        Коментар до замовлення
      </Typography>

      <Input
        placeholder="Залиште комментар..."
        value={comment}
        onChange={handleCommentChange}
        onBlur={handleCommentOnBlur}
      />
    </div>
  );
};

import axios from 'axios';

export const generateCode = (length: number) =>
  (Math.floor(Math.random() * (9 * Math.pow(10, length - 1))) + Math.pow(10, length - 1)).toString();

type SenderParams = {
  phone: string;
  text: string;
};

const smsServiceUrl = 'https://api.turbosms.ua/message/send.json';
export const baseUrl = 'https://budprokat-cors.herokuapp.com/';

export const sendSms = async ({ text, phone }: SenderParams): Promise<{ success: boolean }> => {
  // TODO: Refactor - Use Thunk (async action)
  const smsToken = process.env.REACT_APP_TURBOSMS_TOKEN;
  const smsSender = process.env.REACT_APP_TURBOSMS_SENDER;

  if (!smsToken || !smsSender) return { success: false };

  const data = {
    recipients: [phone],
    sms: {
      sender: smsSender,
      text,
    },
  };

  try {
    const response = await axios.post(`${baseUrl}${smsServiceUrl}`, data, {
      headers: {
        Authorization: `Bearer ${smsToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.data.response_code !== 801) return { success: false };
    return { success: true };
  } catch {
    return { success: false };
  }
};

export const getAllPhoneVariants = (phone: string) => {
  const phoneNumber = phone.replace('+380', '');
  const phoneNumberWithoutSpaces = phoneNumber.split(/\s+/).join('');
  return [
    phone,
    `380 ${phoneNumber}`,
    `380${phoneNumberWithoutSpaces}`,
    `0${phoneNumber}`,
    `0${phoneNumberWithoutSpaces}`,
  ];
};

import React, { useCallback, useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import cls from 'classnames';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { choseDate, choseTime, clearDateTime, selectChosenDate, selectChosenTime } from './deliveryDateTimeSlice';
import { DayPicker } from './components/DayPicker/DayPiker';
import { addDay, isSunday, isWeekday, isWorkingHours, prepareDate } from './DeliveryDateTIme.utils';
import { Button } from '../../shared/Button/Button';
import moment from 'moment';
import Typography from '../../shared/Typography/Typography';
import { DateTimeTypeEnum } from './DeliveryDateTime.enums';
import { ReactComponent as QuestionIcon } from '../../assets/icons/Question.svg';
import styles from './DeliveryDateTime.module.css';
import { TimeSelect } from './components/TimeSelect/TimeSelect';
import { TimeOption } from './components/TimeSelect/TimeSelect.types';

export function DeliveryDateTime() {
  const chosenDate = useAppSelector(selectChosenDate);
  const dispatch = useAppDispatch();
  const [dateTimeType, setDateTimeType] = useState<DateTimeTypeEnum | null>(null);
  const [date, setDate] = useState<Date | undefined>();
  const [showDayPicker, setShowDayPicker] = useState<boolean>(false);

  const choseDateDispatch = useCallback(
    (day: Date) => dispatch(choseDate({ chosenDate: prepareDate(day) })),
    [dispatch],
  );

  const handleClickSubmit = useCallback(() => {
    if (date) {
      choseDateDispatch(date);
      setShowDayPicker(false);
    }
  }, [choseDateDispatch, date]);

  const handleTimeChange = useCallback(
    (time: TimeOption) => {
      dispatch(choseTime({ chosenTime: time }));
    },
    [dispatch],
  );

  const handleClick = useCallback(
    (type: DateTimeTypeEnum) => () => {
      setDateTimeType(type);
      setShowDayPicker(false);
      dispatch(clearDateTime());

      switch (type) {
        case DateTimeTypeEnum.TODAY: {
          setDate(undefined);
          choseDateDispatch(new Date());
          break;
        }
        case DateTimeTypeEnum.TOMORROW: {
          setDate(undefined);
          if (!isWeekday(new Date())) {
            return choseDateDispatch(addDay(new Date(), 2));
          }
          choseDateDispatch(addDay(new Date(), 1));
          break;
        }
        case DateTimeTypeEnum.OTHER: {
          setShowDayPicker(true);
          break;
        }

        default:
          break;
      }
    },
    [choseDateDispatch, dispatch],
  );

  const isTodayButtonDisabled = !isWorkingHours(isWeekday(new Date())) || isSunday(new Date());

  return (
    <div className={styles.dateTimeWrapper}>
      {isTodayButtonDisabled && (
        <ReactTooltip
          id="dateTimeBtn-tip"
          place="right"
          type="dark"
          effect="solid"
          getContent={() => (
            <p style={{ maxWidth: 200, lineHeight: 1.5 }}>
              Ви оформляєте резерв в неробочий час, тому вибрати можна тільки завтрашній день чи іншу дату.
            </p>
          )}
        />
      )}
      <ReactTooltip
        id="dateTimeTitle-tip"
        place="bottom"
        type="dark"
        effect="solid"
        getContent={() => (
          <p style={{ maxWidth: 200, lineHeight: 1.5 }}>
            Наявність товару буде підтверджено в телефонному режимі протягом 30 хвилин в робочий час або вранці
            наступного дня, якщо ви замовляєте в неробочий час.
          </p>
        )}
      />

      <Typography className={styles.dateTimeTitle} variant="h2" component="h2">
        Зарезервувати на <QuestionIcon data-tip data-for="dateTimeTitle-tip" className={styles.questionIcon} />
      </Typography>

      <div className={styles.buttonWrapper}>
        <Button
          className={cls(isTodayButtonDisabled && styles.disabledBtn)}
          data-tip
          data-for="dateTimeBtn-tip"
          variant={dateTimeType === DateTimeTypeEnum.TODAY ? 'main' : 'outline'}
          disabled={isTodayButtonDisabled}
          onClick={handleClick(DateTimeTypeEnum.TODAY)}
        >
          Сьогоднi
        </Button>
        <Button
          variant={dateTimeType === DateTimeTypeEnum.TOMORROW ? 'main' : 'outline'}
          onClick={handleClick(DateTimeTypeEnum.TOMORROW)}
        >
          {isWeekday(new Date()) ? 'Завтра' : 'Понеділок'}
        </Button>
        <Button
          variant={dateTimeType === DateTimeTypeEnum.OTHER ? 'main' : 'outline'}
          onClick={handleClick(DateTimeTypeEnum.OTHER)}
        >
          {date ? moment(date).format('DD.MM') : 'Інше'}
        </Button>
      </div>
      {showDayPicker && (
        <div className={styles.dayPikerSection}>
          <Typography component="h2" className={styles.dateTimeNotion} variant="caption">
            Послуга резервування на іншу дату коштує <b>250 грн.</b> Якщо згодні - оберіть дату.
          </Typography>
          <DayPicker setDate={setDate} date={date} />
          <Button className={styles.choseDate} onClick={handleClickSubmit} disabled={!date}>
            Обрати дату
          </Button>
        </div>
      )}
      {chosenDate && <TimeSelect onChange={handleTimeChange} chosenDate={chosenDate} />}
    </div>
  );
}
